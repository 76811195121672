module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PQP4FH9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"4EQtIyabAoAFQCg5qRoYEQ","gtmPreview":"env-1","dataLayerName":"dataLayer","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GTM-PQP4FH9","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"キンクラ - 勤怠管理クラウド","short_name":"キンクラ","start_url":"/","display":"minimal-ui","icon":"content/assets/logo512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9dd8d1a3f54e52fcc62854fa4ae48716"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
